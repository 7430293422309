import React from 'react';
import Layout from 'components/common/layout';
import { Heading, Text } from 'components/common/typography';
import { Article } from 'components/pages/category/template';
import SEO from 'components/common/seo';

const AboutPage = () => (
    <Layout>
        <SEO 
            title="About Us"
        />
        <Article>
            <Heading appearance="trafalgar">About Activ Business Solutions</Heading>
            <Text appearance="pica">Our vision is to be the industry benchmark for quality in all business and office solutions.</Text>

            <Heading appearance="paragon" as="h2">Customer Care</Heading>
            <Text>Openness, honesty and transparency is at the heart of our client relationships. Our client pricing structure is key to controlling your expenditure, but this can only be achieved if you have a streamlined ordering system, pro-active account manager and detailed management reporting, enabling your business to focus on its own challenges whilst your business products are managed with expertise.</Text>

            <Heading appearance="paragon" as="h2">Price</Heading>
            <Text>There’s no avoiding it is there! We know and understand that every customer wants to reduce cost wherever possible so we use this as a starting position. Our experience in the not for profit market defines our approach to client pricing. Our pricing strategy is a simple one – transparency in all areas & definition of our expectations as a supplier.</Text>

            <Heading appearance="paragon" as="h2">Account Management</Heading>
            <Text>The management of our client is critical to our success in demonstrating care, support, recommendations, maintenance, and consistent cost savings across all product areas. From every member of our business, whether our delivery team, our digital services division, or a designated Account Manager, we provide excellence in communication and support to offer the highest level of quality within the industry.</Text>
        </Article>
    </Layout>
);

export default AboutPage;